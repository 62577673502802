import {
  mergeClasses,
  Tab,
  TabList as TabListBase,
} from "@fluentui/react-components";
import type {
  SelectTabData,
  SelectTabEvent,
  TabListProps,
  TabValue,
} from "@fluentui/react-components";
import AnnualReviewIcon from "../../assets/annual_review.svg?react";
import { useContext, useEffect, useRef, useState } from "react";
import { useMatch, useNavigate, useLocation } from "react-router-dom";
import styles from "./tabList.module.scss";
import { transformDbToUI } from "../../utils";
import { AppContext, AppDispatchContext } from "../../AppContext";
import { TEngagement, TLearner } from "../../types";
import StudentName from "../studentName/studentName";

export const TabList = (props: Partial<TabListProps>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabListRef = useRef<HTMLDivElement>(null);

  const match = useMatch("/engagement/:engagementId/:tab");
  const dispatch = useContext(AppDispatchContext);
  const {
    isAnnualReview,
    engagement: engagementFromContext,
    learners: learnersFromContext,
  } = useContext(AppContext);

  const engagementId = match?.params?.engagementId || 0;
  const [selectedValue, setSelectedValue] = useState<TabValue>(
    match?.params?.tab || "details"
  );

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element?.id.match(/\d/)) {
      }
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    setSelectedValue(match?.params?.tab);
  }, [match?.params?.tab]);

  const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
    navigate(`/engagement/${match?.params?.engagementId || 0}/${data.value}`);

    // Reset scroll position
    if (tabListRef.current) {
      const headerHeight =
        document.querySelector("header")?.getBoundingClientRect().height || 0;
      const { height } = tabListRef.current.getBoundingClientRect();
      window.scrollTo({ top: height + headerHeight + 25, behavior: "smooth" });
    }
  };

  const fetchLearners = async (): Promise<TLearner[] | undefined> => {
    if (learnersFromContext?.length > 0) {
      return learnersFromContext;
    }

    const response = await fetch(`/api/learners`);
    if (!response.ok) {
      throw new Error("Error fetching learners");
    }

    const learners = await response.json();
    dispatch({ type: "SET_LEARNERS", payload: learners });
    return learners;
  };

  const fetchEngagement = async (): Promise<TEngagement | undefined> => {
    if (engagementFromContext?.engagementId) {
      return engagementFromContext;
    }

    const response = await fetch(
      `/api/engagements?engagementId=${engagementId}`
    );

    if (!response.ok) {
      throw new Error("Error fetching engagement");
    }

    const engagement = await response.json();
    const transformedData = transformDbToUI(engagement);

    dispatch({
      type: "SET_ENGAGEMENT",
      payload: transformedData,
    });

    if (!transformedData.isDraft) {
      dispatch({
        type: "SET_EDIT_MODE",
        payload: false,
      });
    }

    dispatch({
      type: "SET_ANNUAL_REVIEW",
      payload: !!transformedData.isAnnualReview,
    });
    return transformedData;
  };

  useEffect(() => {
    async function fetchData() {
      await fetchEngagement();
      await fetchLearners();
    }
    fetchData();
  }, [engagementId]);

  return (
    <div className={styles.root} ref={tabListRef}>
      <TabListBase
        {...props}
        defaultSelectedValue="details"
        reserveSelectedTabSpace={false}
        onTabSelect={onTabSelect}
        className={styles.tabList}
        selectedValue={selectedValue}
      >
        <Tab value="details" className={styles.tab} aria-label="Details tab">
          <div className={styles.tabContent}>
            <img className={styles.tabIcons} src="/icons/details.svg" alt="" />
            <span>Details</span>
          </div>
        </Tab>
        <Tab
          value="milestones"
          className={styles.tab}
          aria-label="Milestones tab"
        >
          <div className={styles.tabContent}>
            <img
              className={styles.tabIcons}
              src="/icons/milestones.svg"
              alt=""
            />
            <span>Milestones</span>
          </div>
        </Tab>
        <Tab
          value="progress"
          className={styles.tab}
          aria-label="Progress review tab"
        >
          <div className={styles.tabContent}>
            <img className={styles.tabIcons} src="/icons/progress.svg" alt="" />
            <span>Progress review</span>
          </div>
        </Tab>
        <Tab value="goals" className={styles.tab} aria-label="Goals tab">
          <div className={styles.tabContent}>
            <img className={styles.tabIcons} src="/icons/goals.svg" alt="" />
            <span>Goals</span>
          </div>
        </Tab>
        <Tab
          value="review"
          className={styles.tab}
          disabled={!isAnnualReview}
          aria-label="Annual review tab"
        >
          <div
            className={mergeClasses(
              styles.tabContent,
              !isAnnualReview ? styles.disabledTab : ""
            )}
          >
            <AnnualReviewIcon className={styles.tabIcons} aria-hidden />
            <span>Annual review</span>
          </div>
        </Tab>
        <Tab value="next-visit" className={styles.tab}>
          <div className={styles.tabContent}>
            <img
              className={styles.tabIcons}
              src="/icons/next_visit.svg"
              alt="Next visit"
            />
            <span>Next visit</span>
          </div>
        </Tab>
      </TabListBase>
      <StudentName />
    </div>
  );
};

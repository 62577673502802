import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "../button";
import { SkillLevel } from "../../types";
import styles from "./outcomeRating.module.scss";
import {
  StarEmphasisFilled,
  StarRegular,
  ArrowRight32Filled,
  CheckmarkCircleFilled,
} from "@fluentui/react-icons";
import { AppContext } from "../../AppContext";
import { stopPropagation } from "../../utils";

interface Props {
  skillId: string;
  assignmentId: number;
  assignmentGroupId: number;
  ratings: SkillLevel[];
  currentSkillLevel?: SkillLevel;
  changedSkillLevel?: SkillLevel | null;
  isGoalSet: boolean;
  pointsPossible: number;
  isCapstoneOutcome: boolean;
  resetSkillChange: () => void;
  onSkillUpdate: (data: Record<string, any>) => void;
}

const OutcomeRating: React.FC<Props> = (props) => {
  const { engagement } = useContext(AppContext);
  const [nextRating, setNextRating] = useState<SkillLevel | null>(null);
  const { onSkillUpdate } = props;
  const currentRating = props.currentSkillLevel;

  const [isLevelChanged, setIsLevelChanged] = useState<boolean>(false);
  const [goal, setGoal] = useState(props.isGoalSet);
  const finalRating = props.ratings[props.ratings.length - 1];

  const setInitialRating = () => {
    if (!currentRating) {
      setNextRating(props.ratings[0]);
      return;
    }
    const currentRatingIndex = props.ratings.findIndex(
      (r: SkillLevel) => r.id === currentRating?.id
    );

    const nextRating = props.ratings[currentRatingIndex + 1];
    setNextRating(nextRating);
  };

  useEffect(() => {
    if (props.changedSkillLevel) {
      setNextRating(props.changedSkillLevel);
      setIsLevelChanged(true);
      return;
    } else {
      setInitialRating();
    }
  }, [props.ratings]);

  const handleRating = useCallback(() => {
    if (!nextRating) return;

    let nextChangedRating = nextRating;

    if (isLevelChanged) {
      const nextRatingIndex = props.ratings.indexOf(nextRating);
      const nextIndex = nextRatingIndex + 1;
      nextChangedRating = props.ratings[nextIndex];
      if (!nextChangedRating) {
        setInitialRating();
        setIsLevelChanged(false);
        setGoal(false);

        props.resetSkillChange();
        return;
      } else {
        setNextRating(nextChangedRating || props.ratings[0]);
      }
    }

    onSkillUpdate({
      changedSkillLevelId: nextChangedRating?.id,
      changedSkillLevelName: nextChangedRating?.description,
      changedSkillLevelPoints: nextChangedRating?.points,
    });

    setIsLevelChanged(true);
  }, [nextRating, isLevelChanged]);

  const isOutcomeComplete = currentRating?.points === props.pointsPossible;

  const handleGoalClick = (newGoalState: boolean) => {
    if (!engagement?.isDraft) return;
    setGoal(newGoalState);
    onSkillUpdate({ goal: newGoalState });
  };

  return (
    <div className={styles.root}>
      {goal || isOutcomeComplete ? (
        <StarEmphasisFilled
          className={`${styles.goalIcon} ${
            isOutcomeComplete ? styles.completed : ""
          }`}
          onClick={stopPropagation(() => handleGoalClick(false))}
          onKeyDown={stopPropagation(() => handleGoalClick(false))}
          onMouseDown={stopPropagation()}
          tabIndex={0}
          role="button"
          aria-pressed={props.isCapstoneOutcome}
        />
      ) : (
        <StarRegular
          className={`${styles.goalIconRegular} ${
            props.isCapstoneOutcome ? styles.capstone : ""
          }`}
          onClick={stopPropagation(() => handleGoalClick(true))}
          onKeyDown={stopPropagation(() => handleGoalClick(false))}
          onMouseDown={stopPropagation()}
          tabIndex={0}
          role="button"
          aria-pressed={props.isCapstoneOutcome}
        />
      )}
      <div className={styles.ratingContainer}>
        {!isOutcomeComplete ? (
          <>
            <span> {currentRating?.description || "Not assessed"}</span>
            <ArrowRight32Filled />
          </>
        ) : (
          <></>
        )}
        <Button
          content={
            isOutcomeComplete
              ? finalRating.description
              : nextRating?.description
          }
          onClick={() => {
            if (isOutcomeComplete) return;
            handleRating();
          }}
          className={`${styles.button} ${
            isOutcomeComplete ? styles.completed : ""
          } ${
            (isLevelChanged || !!props.changedSkillLevel) && !isOutcomeComplete
              ? styles.levelChanged
              : ""
          }`}
          iconPosition="before"
          icon={isOutcomeComplete ? <CheckmarkCircleFilled /> : null}
          disabled={!engagement?.isDraft}
        />
      </div>
    </div>
  );
};

export default OutcomeRating;

import React, { useContext } from "react";
import Header from "./components/header";
import { Outlet } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { makeStyles } from "@fluentui/react-components";
import { AppContext } from "./AppContext";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import AppLoaderSvg from "./assets/app_loader.svg?react";
import { reactPlugin } from "./AppInsights";

const useStyles = makeStyles({
  root: {
    background: "url('/background-2025.svg') top center no-repeat",
    backgroundSize: "cover",
    minHeight: "100vh",
    height: "100%",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100vh",
  },
  loader: {
    width: "90%",
    height: "50%",
    fontSize: "1.2rem",
    "@media (min-width: 480px)": {
      width: "70%",
      height: "70%",
      fontSize: "1.5rem",
    },
    "@media (min-width: 768px)": {
      width: "50%",
      height: "50%",
    },
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
});

const AppLayout: React.FC = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { online } = useContext(AppContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <Header />
        {isLoggedIn || !online ? (
          <Outlet />
        ) : (
          <div className={classes.loaderContainer}>
            <div className={classes.loader}>
              <AppLoaderSvg />
              <span>Establishing database connection...</span>
            </div>
          </div>
        )}
      </AppInsightsContext.Provider>
    </div>
  );
};

export default AppLayout;

import dayjs from "dayjs";
import { createContext } from "react";
import {
  AssignmentGroups,
  LearnerCRMData,
  TEngagement,
  TError,
  TLearner,
} from "./types";

import { object, string } from "yup";

type EngagementResponse = TEngagement;

export type Milestone = {
  engagementId: number;
  assignmentGroups: AssignmentGroups;
};

export interface AppState {
  online: boolean;
  offlineMode: boolean;
  offlineTimestamp: number | undefined;
  editMode: boolean;
  engagementDate: number;
  engagement: EngagementResponse;
  engagements: EngagementResponse[];
  learners: TLearner[];
  isAnnualReview: boolean;
  isEngagementDateToday: boolean;
  isEngagementDatePast: boolean;
  learnersCRMData: LearnerCRMData[];
  milestones: Milestone[];
  schema: Record<string, any>;
  errors: TError[];
  isEngagementsLoading: boolean;
}

// Define the action types
export type Action =
  | { type: "SET_ENGAGEMENTS_LOADING"; payload: boolean }
  | { type: "SET_NETWORK_STATUS"; payload: boolean }
  | { type: "SET_OFFLINE_MODE"; payload: boolean }
  | { type: "SET_EDIT_MODE"; payload: boolean }
  | { type: "SET_ENGAGEMENT_DATE"; payload: number }
  | { type: "RESET_ENGAGEMENT" }
  | { type: "INITIALIZE_ENGAGEMENT"; payload: TEngagement }
  | { type: "SET_ENGAGEMENT"; payload: Record<string, any> }
  | { type: "SET_MILESTONE_CHANGE"; payload: Record<string, any> }
  | { type: "REMOVE_MILESTONE_CHANGE"; payload: Record<string, any> }
  | { type: "SET_ENGAGEMENTS"; payload: TEngagement[] }
  | { type: "SET_ANNUAL_REVIEW"; payload: boolean }
  | { type: "SET_LEARNERS"; payload: TLearner[] }
  | { type: "SET_LEARNERS_CRM_DATA"; payload: LearnerCRMData[] }
  | { type: "SET_MILESTONES"; payload: Milestone[] }
  | { type: "SET_MILESTONE"; payload: Milestone }
  | { type: "ADD_FIELD_TO_SCHEMA"; payload: Record<string, any> }
  | { type: "REMOVE_FIELD_FROM_SCHEMA"; payload: string }
  | { type: "SET_ERRORS"; payload: TError[] }
  | { type: "RESET_SCHEMA" }
  | { type: "RESET_ERRORS" };

export const AppContext = createContext<AppState>(null as any);

export const AppDispatchContext = createContext<React.Dispatch<Action>>(
  null as any
);

export const initialAppState = {
  online: window.navigator.onLine,
  offlineMode: false,
  offlineTimestamp: undefined,
  editMode: true,
  engagementDate: dayjs().hour(6).minute(0).second(0).millisecond(0).valueOf(),
  engagement: {} as TEngagement,
  engagements: [],
  isAnnualReview: false,
  learners: [],
  learnersCRMData: [],
  isEngagementDateToday: true,
  isEngagementDatePast: false,
  milestones: [],
  errors: [],
  schema: object({
    siteSafety: string().required("Details: Site safety#siteSafety"),
    participants: string().required("Details: Participants#participants"),
    location: string().required("Details: Location#location"),
    currentWork: string().required("Progress: Current project#currentWork"),
    projectsWorkedOn: string().required(
      "Progress: Recent projects#projectsWorkedOn"
    ),
    annualSupportPlan: string().when("isAnnualReview", {
      is: true,
      then: (schema) =>
        schema.required(
          "Annual Review: Annual goals support plan#annualSupportPlan"
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
    annualDevelopmentGoals: string().when("isAnnualReview", {
      is: true,
      then: (schema) =>
        schema.required("Annual Review: Annual goals#annualDevelopmentGoals"),
      otherwise: (schema) => schema.notRequired(),
    }),
    nextVisitDate: string().when("isCompleting", {
      is: true,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.required("Next Visit: Next visit date#nextVisitDate"),
    }),
  }),
  isEngagementsLoading: false,
};

export function appReducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case "SET_ENGAGEMENTS_LOADING":
      return { ...state, isEngagementsLoading: action.payload };
    case "SET_EDIT_MODE":
      return { ...state, editMode: action.payload };
    case "SET_OFFLINE_MODE":
      return { ...state, offlineMode: action.payload };
    case "SET_NETWORK_STATUS": {
      const networkStatus: boolean = state.offlineMode ? false : action.payload; // Force network status to false if offline mode is enabled
      const offlineTimestamp = networkStatus ? undefined : Date.now();

      // Determine if a state change is required
      const canUpdate = state.online || window.navigator.onLine;

      if (canUpdate && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: "SET_ONLINE_STATUS",
          online: networkStatus,
        });
      }

      return {
        ...state,
        online: canUpdate ? networkStatus : state.online,
        offlineTimestamp,
      };
    }
    case "SET_ENGAGEMENT_DATE": {
      const isToday = dayjs(action.payload).isSame(dayjs(), "day");
      const isPast = dayjs(action.payload).isBefore(dayjs(), "day");
      return {
        ...state,
        engagementDate: action.payload,
        isEngagementDateToday: isToday,
        isEngagementDatePast: isPast,
      };
    }
    case "SET_ENGAGEMENTS":
      return { ...state, engagements: action.payload };
    case "SET_ENGAGEMENT":
      return {
        ...state,
        engagement: { ...state.engagement, ...action.payload },
      };
    case "INITIALIZE_ENGAGEMENT": {
      return {
        ...state,
        engagement: action.payload,
        errors: [],
        milestones: [],
      };
    }
    case "RESET_ENGAGEMENT":
      return { ...state, engagement: {} as TEngagement };
    case "REMOVE_MILESTONE_CHANGE": {
      const { engagement } = state;
      const {
        totalPercentageDiff,
        totalCompletionPercentage,
        ...milestoneItems
      } = engagement?.milestones || {};
      const milestoneChanges = milestoneItems || {};
      const { assignmentGroupId, assignmentId, skillId } = action.payload;
      if (milestoneChanges[assignmentGroupId]?.[assignmentId]?.[skillId])
        delete milestoneChanges[assignmentGroupId][assignmentId][skillId];

      const { assignmentName, assignmentPercentageComplete, ...otherSkills } =
        milestoneChanges[assignmentGroupId]?.[assignmentId] || {};

      if (Object.keys(otherSkills || {}).length === 0) {
        if (milestoneChanges[assignmentGroupId]?.[assignmentId])
          delete milestoneChanges[assignmentGroupId]?.[assignmentId];
        const { assignmentGroupName, ...otherAssignments } =
          milestoneChanges[assignmentGroupId] || {};

        if (Object.keys(otherAssignments || {}).length === 0) {
          if (milestoneChanges[assignmentGroupId])
            delete milestoneChanges[assignmentGroupId];
        }
      }
      return {
        ...state,
        engagement: { ...engagement, milestones: milestoneChanges },
      };
    }

    case "SET_ANNUAL_REVIEW":
      return { ...state, isAnnualReview: action.payload };
    case "SET_LEARNERS":
      return { ...state, learners: action.payload };
    case "SET_LEARNERS_CRM_DATA":
      return { ...state, learnersCRMData: [...action.payload] };
    case "SET_MILESTONES":
      return { ...state, milestones: action.payload };
    case "SET_MILESTONE": {
      const { milestones } = state;
      const isMilestonePresent = milestones.find(
        (m) => m.engagementId === action.payload.engagementId
      );
      if (isMilestonePresent) {
        return state;
      }
      return { ...state, milestones: milestones.concat(action.payload) };
    }
    case "RESET_SCHEMA":
      return { ...state, schema: initialAppState.schema };
    case "ADD_FIELD_TO_SCHEMA":
      return {
        ...state,
        schema: state.schema.shape(action.payload),
      };
    case "REMOVE_FIELD_FROM_SCHEMA": {
      const key = action.payload;
      const { [key]: omit, ...rest } = state.schema.fields;
      return {
        ...state,
        schema: state.schema.shape(rest),
      };
    }
    case "RESET_ERRORS":
      return { ...state, errors: [] };
    case "SET_ERRORS":
      return { ...state, errors: action.payload };

    default:
      return state;
  }
}

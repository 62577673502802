import React, { useContext } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { TabList } from "../components/tabList";
import Swipeable from "../utils/Swipeable";
import styles from "./engagementWrapper.module.scss";
import { AppContext } from "../AppContext";

const EngagementWrapper: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { engagement } = useContext(AppContext);

  const tabs = [
    "details",
    "milestones",
    "progress",
    "goals",
    "review",
    "next-visit",
  ];

  const scrollToTop = () => {
    const headerHeight =
      document.querySelector("header")?.getBoundingClientRect().height || 0;
    const tabListHeight =
      document.querySelector("tablist")?.getBoundingClientRect().height || 0;
    window.scrollTo({
      top: tabListHeight + headerHeight + 106,
      behavior: "smooth",
    });
  };

  const swipeEngagementNavigation = (
    direction: "left" | "right",
    currentPage: string
  ) => {
    const availableTabs = engagement.isAnnualReview
      ? tabs
      : tabs.filter((tab) => tab !== "review");
    const currentIndex = availableTabs.indexOf(currentPage);
    let destinationIndex =
      direction === "left" ? currentIndex + 1 : currentIndex - 1;
    if (destinationIndex < 0 || destinationIndex >= availableTabs.length)
      return;
    navigateToDestination(availableTabs[destinationIndex]);
  };

  const navigateToDestination = (destination: string) => {
    scrollToTop();
    console.log("Navigating to destination: ", destination);
    navigate(`/engagement/${engagement.engagementId}/${destination}`);
  };

  return (
    <>
      <Swipeable
        onSwipeLeft={() =>
          swipeEngagementNavigation("left", location.pathname.split("/")[3])
        }
        onSwipeRight={() =>
          swipeEngagementNavigation("right", location.pathname.split("/")[3])
        }
      >
        <div className={styles.root}>
          <TabList />
          <div className={styles.container}>
            <Outlet />
          </div>
        </div>
      </Swipeable>
    </>
  );
};

export default EngagementWrapper;

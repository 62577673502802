import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Combobox,
  ComboboxProps,
  useComboboxFilter,
  Option,
} from "@fluentui/react-components";

import { PersonAdd28Regular } from "@fluentui/react-icons";
import DatePicker from "react-datepicker";
import styles from "./addLeaner.module.scss";
import { CompoundButton } from "../compoundButton";
import { Button } from "../button";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CloseIcon from "../../assets/close_regular.svg?react";
import dayjs from "dayjs";
import { AppContext, AppDispatchContext } from "../../AppContext";
import { fetchEngagements, fetchLearnersCRMData } from "../../utils";

type CalendarIconProps = {
  onClick?: () => void;
  value?: Date | null;
};

export const AddLearner = () => {
  const [query, setQuery] = useState<string>("");
  const [open, setOpen] = useState(false);

  const { engagementDate, learners, online } = useContext(AppContext);
  const [engagementDateTime, setEngagementDateTime] = useState<Date | null>(
    new Date(engagementDate)
  );
  const [status, setStatus] = useState<boolean | undefined>(undefined);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const dispatch = useContext(AppDispatchContext);

  useEffect(() => {
    setEngagementDateTime(new Date(engagementDate));
  }, [engagementDate]);

  const addEngagement = useCallback(async () => {
    setRequestInProgress(true);
    const [learnerId, courseId] = query.split("_");
    const learnerData = learners.find(
      (learner) =>
        String(learner.learnerId) === learnerId &&
        String(learner.courseId) === courseId
    );
    if (!learnerData) return;

    const scheduledTime = dayjs(engagementDateTime)
      .second(0)
      .millisecond(0)
      .valueOf();

    try {
      const response = await fetch(`/api/engagements`, {
        method: "POST",
        body: JSON.stringify({
          learnerId,
          courseId,
          learnerSisUserId: learnerData.learnerSisUserId,
          learnerName: learnerData.learnerName,
          courseName: learnerData.courseName,
          courseDescription: learnerData.courseDescription,
          scheduledTime,
        }),
      });
      if (response.ok) {
        setStatus(true);
        setQuery("");
        setEngagementDateTime(new Date(engagementDate));
        await fetchEngagements(engagementDate, dispatch);
        fetchLearnersCRMData(learnerData.learnerSisUserId).then((data) => {
          dispatch({
            type: "SET_LEARNERS_CRM_DATA",
            payload: [data],
          });
        });
        setOpen(false);
      }
      setRequestInProgress(false);
    } catch (error) {
      console.error("Error adding engagement", error);
    }
  }, [query, engagementDateTime, learners]);

  const formattedLearners = (learners || []).map((learner) => ({
    ...learner,
    value: `${learner.learnerId}_${learner.courseId}` || "",
    disabled: learner.learnerStatus !== "active",
    children: (
      <Option
        text={learner.learnerName}
        value={`${learner.learnerId}_${learner.courseId}`}
        className={styles.learnerDropdown}
        disabled={learner.learnerStatus !== "active"}
      >
        <div className={styles.learnerOption}>
          <span>{learner.learnerName}</span>
          <span>{learner.courseName}</span>
        </div>
      </Option>
    ),
  }));

  const children = useComboboxFilter(query, formattedLearners, {
    noOptionsMessage: "No learners found",
    filter: (optionText: string, query: string) =>
      optionText?.toLowerCase().includes(query?.toLowerCase()),
    optionToText: (option) => option.learnerName,
    optionToReactKey: (option) => `${option?.learnerId}_${option?.courseId}`,
  });

  const onOptionSelect: ComboboxProps["onOptionSelect"] = (_, data) => {
    if (!data) return;

    setQuery(data.optionValue ?? "");
  };

  const isPrimaryDisabled = !query || !engagementDateTime;
  const selectedLearner = learners.find(
    (l) => `${l.learnerId}_${l.courseId}` === query
  );

  const minTime = dayjs(engagementDate)
    .set("hour", 6)
    .set("minute", 0)
    .set("second", 0)
    .toDate(); // 6.00AM
  const maxTime = dayjs().hour(17).minute(0).second(0).toDate(); // 5:00PM

  const CustomInput = forwardRef<HTMLImageElement, CalendarIconProps>(
    ({ onClick, value }, ref) => (
      <div onClick={onClick} ref={ref}>
        <input
          id="engagementDate"
          value={value?.toLocaleString() || ""}
          readOnly
        />
      </div>
    )
  );

  useEffect(() => {
    if (open) {
      document.documentElement.classList.add("no-scroll");
      if (engagementDateTime) {
        setEngagementDateTime(minTime);
      }
    } else {
      document.documentElement.classList.remove("no-scroll");
    }

    // Cleanup on component unmount
    return () => {
      document.documentElement.classList.remove("no-scroll");
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      onOpenChange={(_, data) => setOpen(data.open)}
      modalType="modal"
    >
      <DialogTrigger disableButtonEnhancement>
        <CompoundButton
          content=""
          icon={<PersonAdd28Regular />}
          disabled={!online}
          className={styles.addLearnerButton}
        />
      </DialogTrigger>
      <DialogSurface>
        <DialogBody className={styles.dialogBody}>
          <DialogTitle className={styles.dialogTitle}>
            <span>Add a learner to schedule</span>
            <DialogTrigger disableButtonEnhancement>
              <CloseIcon className={styles.closeIcon} />
            </DialogTrigger>
          </DialogTitle>
          <DialogContent>
            <div className={styles.dialogContent}>
              <div className={styles.leanerCombobox}>
                <label id="learner">Select a learner</label>
                <Combobox
                  className={styles.root}
                  onOptionSelect={onOptionSelect}
                  aria-labelledby="learner"
                  placeholder="Select a learner"
                  onChange={(ev) => setQuery(ev.target.value)}
                  value={
                    selectedLearner
                      ? `${
                          selectedLearner?.learnerName
                        }-${selectedLearner?.courseName?.trim()}`
                      : query
                  }
                  size="large"
                  clearable
                >
                  {children}
                </Combobox>
              </div>
              <div className={styles.timePickerWrapper}>
                <label id="visit-time">Set visit time</label>
                <div className={styles.timeWrapper}>
                  <DatePicker
                    className={styles.timePicker}
                    popperClassName={styles.popper}
                    calendarClassName={styles.calendar}
                    selected={engagementDateTime}
                    onChange={(date) => setEngagementDateTime(date)}
                    aria-labelledby="visit-time"
                    minTime={minTime}
                    maxTime={maxTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    customInput={<CustomInput value={engagementDateTime} />}
                  />
                </div>
              </div>
            </div>
            {typeof status !== "undefined" && (
              <p>
                {!status && (
                  <span>Adding engagement failed please try again</span>
                )}
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                content="Cancel"
                size="small"
                onClick={() => setOpen(false)}
              />
            </DialogTrigger>
            <Button
              appearance="primary"
              content="Add learner"
              disabled={isPrimaryDisabled || requestInProgress}
              onClick={addEngagement}
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

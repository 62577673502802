import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { Button } from "../button";
import { useContext, useState } from "react";
import styles from "./confirmDeleteEngagement.module.scss";
import { EngagementEvent } from "../../types";
import { fetchEngagements } from "../../utils";
import { AppContext, AppDispatchContext } from "../../AppContext";

export const ConfirmDeleteEngagement = ({
  isConfirmDeleteOpen,
  setIsConfirmDeleteOpen,
  engagement,
}: {
  isConfirmDeleteOpen: boolean;
  setIsConfirmDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>;
  engagement: EngagementEvent | undefined;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { engagementDate } = useContext(AppContext);
  const dispatch = useContext(AppDispatchContext);

  const deleteEngagement = async (engagementId: number) => {
    setIsSubmitting(true);

    const resp = await fetch(`/api/engagements?engagementId=${engagementId}`, {
      method: "DELETE",
    });

    setIsSubmitting(false);

    if (resp.ok) {
      setIsConfirmDeleteOpen(false);
      await fetchEngagements(engagementDate, dispatch);
    }
  };

  if (!engagement) return null;

  return (
    <Dialog open={isConfirmDeleteOpen} modalType="modal">
      <DialogSurface>
        <DialogBody className={styles.dialogBody}>
          <DialogTitle className={styles.dialogTitle}>
            <span>Confirm deletion</span>
          </DialogTitle>
          <DialogContent>
            <div className={styles.dialogContent}>
              <div className={styles.text}>
                <label>
                  Are you sure you want to delete the engagement with
                  <br />
                  {engagement.learnerName} on{" "}
                  {new Date(Number(engagement.scheduledTime)).toLocaleString(
                    "en-GB",
                    { dateStyle: "medium", timeStyle: "short" }
                  )}
                  ?
                  <br />
                  This cannot be undone.
                </label>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                content="Cancel"
                size="small"
                onClick={() => setIsConfirmDeleteOpen(false)}
              />
            </DialogTrigger>
            <div className={styles.deleteEngagement}>
              <Button
                appearance="primary"
                content="Delete engagement"
                onClick={() => deleteEngagement(engagement.engagementId)}
                disabled={isSubmitting}
              />
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

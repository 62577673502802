import React, { useContext, useMemo } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from "./goals.module.scss";
import { BlueAccordion } from "../blueAccordion";
import { Goal } from "./Goal";
import { GoalData, MilestoneChange, Milestones } from "../../types";
import { TextArea } from "../textArea";
import { AppContext } from "../../AppContext";
import {
  sortBySkillName,
  sortSkillsArray,
  splitAssignmentName,
  toSentenceCase,
  transformDbToUI,
} from "../../utils";
import NavigationButton from "../button/navigationButton";
import { ArrowSquareUpRightRegular } from "@fluentui/react-icons";

const Goals: React.FC = () => {
  const documentationURL =
    "https://bcito.sharepoint.com/:u:/r/sites/HowWeRollV2/SitePages/Documenting-Visits-%26-Engagements-3.0.aspx?csf=1&web=1&e=MnK1wK";

  const { engagement } = useContext(AppContext);
  const previousDbEngagement = engagement?.latestGoals || {};

  const previousEngagement = transformDbToUI(previousDbEngagement);
  const previousMilestones =
    previousEngagement.milestones || ({} as Milestones);
  const previousGoalComments = previousEngagement.goalsComments || {};

  // Map previous milestones by group name and find the assignment that has a goal
  const previousMilestonesMapByGroupName = useMemo<
    Record<string, GoalData[]>
  >((): Record<string, GoalData[]> => {
    const map = {} as Record<string, GoalData[]>;

    const {
      totalPercentageDiff,
      totalCompletionPercentage,
      ...assignmentGroups
    } = previousMilestones;

    Object.keys(assignmentGroups).forEach((assignmentGroupId) => {
      const {
        assignmentGroupName,
        assignmentGroupPercentageComplete,
        changePercentage,
        changePercentageWeighted,
        ...assignments
      } = previousMilestones[assignmentGroupId];

      Object.keys(assignments).forEach((assignmentId) => {
        const {
          assignmentName,
          assignmentPercentageComplete,
          changePercentage,
          ...skills
        } = assignments[assignmentId];

        const goalData: GoalData = {
          assignmentId,
          assignmentName,
          comments:
            previousGoalComments[assignmentGroupId]?.[assignmentId]?.comments,
        };

        const goalAssignedSkills = Object.keys(skills)
          .filter((skillId) => {
            return !!skills[skillId].goal;
          })
          .map((skillId) => skills[skillId]);

        if (goalAssignedSkills.length > 0) {
          if (!map[assignmentGroupName]) {
            map[assignmentGroupName] = [];
          }
          const skillNames = goalAssignedSkills.map((skill) => skill.skillName);
          goalData.skillNames = skillNames;
          map[assignmentGroupName].push(goalData);
        }
      });
    });
    return map;
  }, [previousMilestones]);

  const goals = useMemo<MilestoneChange[]>((): MilestoneChange[] => {
    if (!engagement?.milestones) return [];
    const milestoneStatements: MilestoneChange[] = [];

    const {
      totalPercentageDiff,
      totalCompletionPercentage,
      ...milestoneItems
    } = engagement.milestones || {};
    Object.keys(milestoneItems || []).forEach((assignmentGroupId) => {
      const assignmentGroup = milestoneItems?.[assignmentGroupId];
      const {
        assignmentGroupName,
        changePercentageWeighted,
        changePercentage,
        assignmentGroupPercentageComplete,
        ...assignments
      } = assignmentGroup;

      Object.keys(assignments).forEach((assignmentId) => {
        const assignment = assignmentGroup[assignmentId];
        const {
          assignmentName,
          assignmentPercentageComplete,
          changePercentage,
          ...skills
        } = assignment;
        const milestoneStatement: MilestoneChange = {
          assignmentGroupName,
          assignmentGroupId,
          assignmentName,
          assignmentId,
          skills: [],
        };
        const sortedSkillIds = sortBySkillName(skills);
        sortedSkillIds.forEach((skillId) => {
          const skill = assignment[skillId];
          const skillName = skill.skillName;
          if (skill.goal) {
            milestoneStatement.skills.push({
              skillName,
            });
          }
        });
        if (milestoneStatement.skills.length > 0) {
          milestoneStatements.push(milestoneStatement);
        }
      });
    });
    return milestoneStatements;
  }, [engagement]);

  const lengthOfComments = Object.keys(previousMilestonesMapByGroupName).length;

  const isAnnualReview = engagement?.isAnnualReview === true;
  const navigationButtonProps = isAnnualReview
    ? { destination: "review", content: "Annual review" }
    : {
        destination: "next-visit",
        content: "Next visit",
      };

  return (
    <div className={styles.root}>
      <div>
        <h1>Goals</h1>
        <p className={styles.subTitle}>
          Review and set goals to be achieved before the next visit
        </p>
      </div>
      {!!Object.keys(previousMilestonesMapByGroupName).length &&
        !!engagement?.isDraft && (
          <BlueAccordion title="Previous goals">
            <>
              {Object.keys(previousMilestonesMapByGroupName).map(
                (assignmentGroupName, index) => {
                  const assignments =
                    previousMilestonesMapByGroupName[assignmentGroupName];

                  return (
                    <div key={assignmentGroupName}>
                      {assignments.map((assignment) => {
                        const [assignmentNumber, assignmentName] =
                          splitAssignmentName(assignment.assignmentName);

                        assignment.skillNames = assignment.skillNames
                          ? sortSkillsArray(assignment.skillNames)
                          : [];

                        return (
                          <div key={assignment.assignmentId}>
                            <p>{toSentenceCase(assignmentGroupName)}</p>
                            <h3 className={styles.title}>
                              <strong>{assignmentName}</strong>
                              {` (${assignmentNumber})`}
                            </h3>
                            <div className={styles.goalSkillsContainer}>
                              {assignment.skillNames?.map(
                                (skillName, index) => (
                                  <p key={index}>{skillName}</p>
                                )
                              )}
                              {assignment.comments && (
                                <p>
                                  <h4 className={styles.subTitle}>
                                    Additional comments
                                  </h4>
                                  <Markdown remarkPlugins={[remarkGfm]}>
                                    {assignment.comments}
                                  </Markdown>
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      {index !== lengthOfComments - 1 && (
                        <div className={styles.separator}></div>
                      )}
                    </div>
                  );
                }
              )}
              {!!previousEngagement?.professionalDevelopmentGoals && (
                <>
                  <div className={styles.separator}></div>
                  <h2 className={styles.title}>
                    Professional development goals
                  </h2>
                  <p>{previousEngagement.professionalDevelopmentGoals}</p>
                </>
              )}
              {!!previousEngagement?.agreedLearningSupport && (
                <>
                  <div className={styles.separator}></div>
                  <h2 className={styles.title}>Agreed learning support</h2>
                  <p>{previousEngagement.agreedLearningSupport}</p>
                </>
              )}
              {!!previousEngagement?.upcomingScopeOfWork && (
                <>
                  <div className={styles.separator}></div>
                  <h2 className={styles.title}>Upcoming scope of work</h2>
                  <p>{previousEngagement.upcomingScopeOfWork}</p>
                </>
              )}
            </>
          </BlueAccordion>
        )}

      {goals.map((goal) => (
        <Goal {...goal} key={goal.assignmentId} />
      ))}

      <TextArea
        id="upcomingScopeOfWork"
        label="Upcoming scope of work"
        helpText="Discuss and document the upcoming scope of work"
        required={false}
        info={
          <div className={styles.infoPopup}>
            <h4>Example:</h4>
            <p>
              Dave said they have another new build with slab on ground this
              time starting next month and a renovation later in the year which
              Aroha will be working on.
            </p>
            <a
              href={`${documentationURL}#upcoming-scope-of-work`}
              target="_blank"
            >
              Upcoming scope of work (HWR)
              <ArrowSquareUpRightRegular />
            </a>
          </div>
        }
        value={engagement?.upcomingScopeOfWork}
      />
      <TextArea
        id="professionalDevelopmentGoals"
        label="Professional development goals"
        helpText="Discuss and agree on professional development goals for the next period"
        required={false}
        info={
          <div className={styles.infoPopup}>
            <h4>What to include:</h4>
            <p>
              Professional development goals are used for further development
              (professional and personal) outside of the normal goals set within
              the qualification
            </p>
            <h4>Example:</h4>
            <p>
              <ul>
                <li>Acquire your drivers license</li>
                <li>Learn to back a trailer</li>
                <li>Attend trade shows/Industry events</li>
                <li>Enter AOY in your region</li>
                <li>Apply/attend Outward bound</li>
              </ul>
            </p>
            <a
              href={`${documentationURL}#professional-development-goals`}
              target="_blank"
            >
              Professional development goals (HWR)
              <ArrowSquareUpRightRegular />
            </a>
          </div>
        }
        value={engagement?.professionalDevelopmentGoals}
      />
      <TextArea
        id="agreedLearningSupport"
        label="Agreed learning support"
        helpText="Discuss and document any specific actions the learner and evaluator have agreed to take to support the learners progress"
        required={false}
        info={
          <div className={styles.infoPopup}>
            <h4>What to include:</h4>
            <ul>
              <li>
                What the employer/evaluator said they would do to support the
                learner to progress.
              </li>
              <li>
                What the learner said they would do to support their progress. 
              </li>
            </ul>
            <p>
              <span>Note: </span>
              list any activities that have been agreed to as part of the
              learning support plan, even if they are outside of BCITO
              qualification material. e.g. links to external resources,
              Apprentice of the Year, Off-job Training info, scholarships etc. 
            </p>
            <h4>Example:</h4>
            <ul>
              Dave said before our next visit he would:
              <li>
                Give Aroha the responsibility of marking out the wall framing
                for the next house
              </li>
              <li>
                Teach Aroha how to install door hardware from start to finish
              </li>
            </ul>
            <ul>
              Aroha said before our next visit she would:
              <li>Mark out the wall frames on the next house</li>
              <li>Sharpen a chisel correctly</li>
              <li>Read the information about walls in her resources</li>
              <li>
                Read the information on Learner Focussed Training's website -
                www.lftraining.co.nz
              </li>
            </ul>
            <a
              href={`${documentationURL}#agreed-learning-support`}
              target="_blank"
            >
              Agreed learning support (HWR)
              <ArrowSquareUpRightRegular />
            </a>
          </div>
        }
        value={engagement?.agreedLearningSupport}
      />
      <NavigationButton {...navigationButtonProps} />
    </div>
  );
};

export default Goals;

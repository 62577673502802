import React, { useContext, useState } from "react";
import { EngagementEvent, TEngagement, TLearner } from "../../types";
import styles from "./dashboardItem.module.scss";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AppDispatchContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";
import CalendarErrorRegular from "../../assets/calendar_error.svg?react";
import { MenuItem } from "@fluentui/react-components";
import { BinRecycleRegular } from "@fluentui/react-icons";
import { SubMenu } from "../header/SubMenu";
import { ConfirmDeleteEngagement } from "./confirmDeleteEngagement";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

interface DashboardItemProps {
  events: EngagementEvent[];
  learners: TLearner[];
}

const DashboardItem: React.FC<DashboardItemProps> = ({ events, learners }) => {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [engagementToDelete, setEngagementToDelete] =
    useState<EngagementEvent>();
  const dispatch = useContext(AppDispatchContext);
  const navigate = useNavigate();

  const handleClickedEvent = (event: EngagementEvent) => {
    dispatch({ type: "SET_EDIT_MODE", payload: true });
    const selectedEngagement = event as TEngagement;
    dispatch({
      type: "INITIALIZE_ENGAGEMENT",
      payload: {
        ...selectedEngagement,
        siteSafety: selectedEngagement.siteSafety || "Proceeded as normal",
        isAnnualReview: !!selectedEngagement.isAnnualReview,
      },
    });
    dispatch({
      type: "SET_ANNUAL_REVIEW",
      payload: !!(event as TEngagement).isAnnualReview,
    });
    navigate(`/engagement/${event.engagementId}/details`);
  };

  return (
    <div className={styles.root}>
      <ConfirmDeleteEngagement
        isConfirmDeleteOpen={isConfirmDeleteOpen}
        setIsConfirmDeleteOpen={setIsConfirmDeleteOpen}
        engagement={engagementToDelete}
      />

      <div className={styles.time}>
        {events.map((scheduledEvent, index) => {
          const showScheduledTime =
            scheduledEvent.scheduledTimeHour &&
            scheduledEvent.hourlySlotStartTime !==
              scheduledEvent.scheduledTimeHour;

          const scheduledMins = scheduledEvent.scheduledTimeHour
            ? parseInt((scheduledEvent.scheduledTimeHour || "")?.split(":")[1])
            : 0;

          const position: number =
            scheduledMins > 0 ? (scheduledMins / 15) * 25 : 0;
          const parsedTime = dayjs(scheduledEvent.hourlySlotStartTime, "HH:mm");

          return (
            <div key={index}>
              {(!showScheduledTime ||
                (showScheduledTime && events.length == 1)) && (
                <div
                  className={styles.hourlySlotStartTime}
                  style={{
                    color:
                      scheduledMins == 0 && scheduledEvent.scheduledTimeHour
                        ? "#FFF"
                        : "#005577",
                  }}
                >
                  {parsedTime.format("hh:mma")}
                </div>
              )}

              {/* We render time HH:MM if schedule mins more than 0*/}
              {scheduledMins > 0 && (
                <div
                  className={styles.scheduledTime}
                  style={{ top: `${position - 5}%` }}
                >
                  {dayjs(scheduledEvent.scheduledTimeHour, "HH:mm").format(
                    "hh:mma"
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.hourlySlot}>
        <div className={styles.halfHourDivider}></div>
        {events.map((scheduledEvent, index) => {
          const showScheduledTime =
            scheduledEvent.hourlySlotStartTime !==
            scheduledEvent.scheduledTimeHour;

          const givenDate = dayjs(Number(scheduledEvent.scheduledTime));
          const today = dayjs().startOf("day");
          const isPast = givenDate.isBefore(today, "day");

          const position: number = showScheduledTime
            ? (parseInt(
                (scheduledEvent.scheduledTimeHour || "")?.split(":")[1]
              ) /
                15) *
              25
            : 0;

          const eventWrapperModifierClass = scheduledEvent.isDraft
            ? "eventWrapper__notSubmitted"
            : "eventWrapper__submitted";

          const learner = learners.find(
            (learner) =>
              learner.learnerId === scheduledEvent.learnerId &&
              learner.courseId === scheduledEvent.courseId
          );

          return (
            <div
              key={scheduledEvent.learnerName + index}
              className={`${styles.eventWrapper} ${styles[eventWrapperModifierClass]}`}
              tabIndex={0}
              style={{
                display: scheduledEvent.learnerName === "" ? "none" : "flex",
                top: `${position}%`,
                width: `${100 / events.length}%`,
                left: `${(100 / events.length) * index}%`,
              }}
              role="button"
              aria-label={`Engagement for ${scheduledEvent.learnerName} in ${scheduledEvent.courseName}`}
              onClick={() => handleClickedEvent(scheduledEvent)}
              onKeyDown={() => handleClickedEvent(scheduledEvent)}
            >
              <div className={styles.eventDetails}>
                <span className={styles.learner}>
                  {scheduledEvent.learnerName}
                </span>
                <span className={styles.course}>
                  {learners.length > 0 ? (
                    scheduledEvent.courseId !== learner?.courseId &&
                    scheduledEvent.isDraft ? (
                      <div className={styles.notEnrolled}>
                        <p>
                          (Not enrolled in course) {scheduledEvent.courseName}
                        </p>
                      </div>
                    ) : (
                      scheduledEvent.courseName
                    )
                  ) : (
                    scheduledEvent.courseName
                  )}
                </span>
              </div>
              <span className={styles.status}>
                {!scheduledEvent.isDraft && (
                  <>
                    <img src="/icons/submitted.svg" alt="submitted" />
                    <span>Submitted</span>
                  </>
                )}
                {scheduledEvent.isDraft && isPast && (
                  <>
                    <CalendarErrorRegular width={32} height={32} />
                    <span>Not yet submitted</span>
                  </>
                )}

                <div
                  className={styles.engagementMenu}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                    e.stopPropagation()
                  }
                >
                  <SubMenu
                    children={
                      <div>
                        <MenuItem
                          disabled={!scheduledEvent.isDraft}
                          onClick={(event) => {
                            event.stopPropagation();
                            setEngagementToDelete(scheduledEvent);
                            setIsConfirmDeleteOpen(true);
                          }}
                          icon={<BinRecycleRegular />}
                        >
                          Delete engagement
                        </MenuItem>
                      </div>
                    }
                    customButtonColor="#005577"
                  />
                </div>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardItem;

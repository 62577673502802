import React, { useContext, useEffect } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  InfoLabel,
  Spinner,
} from "@fluentui/react-components";
import { ChevronDownFilled, ChevronUpFilled } from "@fluentui/react-icons";
import { TLearner, LearnerCRMData } from "../../types";
import styles from "./studentDetails.module.scss";
import { AppContext } from "../../AppContext";
import { toSentenceCase } from "../../utils";

interface Props {
  learner?: TLearner;
  learnerCRMData?: LearnerCRMData;
}

const StudentDetails: React.FC<Props> = () => {
  const { engagement, learners, learnersCRMData } = useContext(AppContext);
  const [learner, setLearner] = React.useState<TLearner | undefined>(
    {} as TLearner
  );
  const [learnerCRMData, setLearnerCRMData] = React.useState<LearnerCRMData>(
    {} as LearnerCRMData
  );
  const [isFetching, setIsFetching] = React.useState(true);

  const fetchLearnersCRMData = async (userId: string) => {
    if (learnersCRMData) {
      const leanerCRMData = learnersCRMData.find(
        (l) => l.learnerSisUserId === userId
      );

      if (leanerCRMData) {
        return leanerCRMData;
      }
    }
    const response = await fetch(`/api/crm-data?learnerSisUserId=${userId}`);
    if (!response.ok) {
      throw new Error(
        `Error fetching Hapai data for user ${userId}: ${response.status}`
      );
    }
    const data = await response.json();
    return { learnerSisUserId: userId, ...data };
  };

  useEffect(() => {
    if (!engagement?.engagementId) return;
    const learner = learners.find(
      (learner) =>
        learner.learnerId === engagement.learnerId &&
        learner.courseId === engagement.courseId
    );
    if (!learner) {
      return;
    }
    setLearner(learner || ({} as TLearner));
    fetchLearnersCRMData(learner?.learnerSisUserId).then((learnerCRMData) => {
      setLearnerCRMData(learnerCRMData);
      setIsFetching(false);
    });
  }, [engagement?.engagementId, learners]);

  const [open, setOpen] = React.useState(false);
  const handleToggle = React.useCallback<AccordionToggleEventHandler>(
    (_, data) => {
      setOpen(data.openItems.length === 1);
    },
    []
  );

  const months = parseInt(learnerCRMData?.Months) || 0;

  return (
    <Accordion onToggle={handleToggle} collapsible>
      <AccordionItem value={1} className={styles.accordionWrapper}>
        <AccordionHeader
          expandIcon={
            open ? (
              <ChevronUpFilled fontSize={32} />
            ) : (
              <ChevronDownFilled fontSize={32} />
            )
          }
          expandIconPosition="end"
        >
          <div className={styles.accordionHeader} title="Learner details">
            {isFetching ? (
              <Spinner aria-label={"Loading learner details"} />
            ) : (
              <>
                <h3>{learner?.learnerName}</h3>
                <p>{learner?.courseName}</p>
              </>
            )}
          </div>
        </AccordionHeader>
        <AccordionPanel className={styles.accordionPanel}>
          <div className={styles.detailsGroup}>
            <div className={styles.detailsItem}>
              <span>A0 number</span>
              <span>{learner?.learnerSisUserId}</span>
            </div>
            <div className={styles.detailsItem}>
              <span>
                Time in training
                <InfoLabel
                  size="large"
                  className={styles.fieldLabel}
                  info={
                    <div className={styles.infoPopup}>
                      <strong>Time in training</strong>
                      <p>
                        Time in training is calculated as the learners active
                        duration since joining BCITO, across related programmes
                      </p>
                    </div>
                  }
                ></InfoLabel>
              </span>
              <span>
                {" "}
                {months >= 12
                  ? `${Math.floor(months / 12)} year(s) ${months % 12} month(s)`
                  : `${months} month(s)`}
              </span>
            </div>
            <div className={styles.detailsItem}>
              <span>Company</span>
              <span>{learnerCRMData?.Company}</span>
            </div>
            <div className={styles.detailsItem}>
              <span>Apprentice type</span>
              <span>{toSentenceCase(learnerCRMData?.ApprenticeType)}</span>
            </div>
            <div className={styles.detailsItem}>
              <span>Block course start date</span>
              <span>
                {learnerCRMData?.StartDate
                  ? new Date(learnerCRMData.StartDate).toLocaleDateString(
                      "en-GB"
                    )
                  : ""}
              </span>
            </div>
            <div className={styles.detailsItem}>
              <span>Next block course</span>
              <span>{learnerCRMData?.Course}</span>
            </div>
            {/* To be added in phase 2 */}
            {/* <div className={styles.detailsItem}> 
              <span>Reading & numeracy</span>
              <span>5/5</span>
            </div> */}
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default StudentDetails;
